import * as React from 'react';
import { Select, SelectOption, ThemeProvider } from '@SBGSports/referee-react';
import { REPORT_TYPE } from '../constants';
import { useTranslations } from 'i18n';
import mixpanel from 'mixpanel-browser';
import { EVENTS } from '../../analytics/analytics-events';
import { useHasFeature } from 'api';
import { useHistory, useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import { useModifySearchParameters } from 'reporting/hooks/url-manipulation';
import { useReportTypeStore } from 'shared/state/reporting/reportTypeStore';
import { FrontendConfigFeaturesEnum } from 'oas';
import { DATE_FROM_PARAM, DATE_TO_PARAM } from 'shared/use-query-params';
import { flushSync } from 'react-dom';

interface ReportSelectorProps {
    isActive?: boolean;
}

export const ReportSelector: React.FC<ReportSelectorProps> = (props) => {
    const { isActive } = props;

    const { activityId } = useParams<{ type: string; activityId: string }>();
    const { __ } = useTranslations();
    const history = useHistory();
    const modifySearchParams = useModifySearchParameters();

    const proReporting = useHasFeature(FrontendConfigFeaturesEnum.ProReporting);

    const { reportType, setReportType } = useReportTypeStore();

    useEffect(() => {
        mixpanel?.time_event(EVENTS.reporting.reportTypeSelection);
        return () => {
            mixpanel?.track(EVENTS.reporting.reportTypeSelection, { reportType });
        };
    }, [reportType]);

    const reportOptions: SelectOption[] = useMemo(() => {
        const reports = [
            {
                id: REPORT_TYPE.activity,
                value: __('misc.activity'),
                isSelected: reportType === REPORT_TYPE.activity,
            },
            {
                id: REPORT_TYPE.athlete,
                value: __('reporting.athlete_in_activity'),
                isSelected: reportType === REPORT_TYPE.athlete,
            },
            {
                id: REPORT_TYPE.longitudinal,
                value: __('reporting.longitudinal'),
                isSelected: reportType === REPORT_TYPE.longitudinal,
            },
            {
                id: REPORT_TYPE.match_day,
                value: __('reporting.match_day'),
                isSelected: reportType === REPORT_TYPE.match_day,
            },
            {
                id: REPORT_TYPE.weekly,
                value: __('reporting.weekly'),
                isSelected: reportType === REPORT_TYPE.weekly,
            },
        ];

        if (proReporting) {
            //add status to top of report selector list
            reports.unshift({
                id: REPORT_TYPE.status,
                value: __('reporting.status'),
                isSelected: reportType === REPORT_TYPE.status,
            });
        }

        return reports;
    }, [__, reportType, proReporting]);

    const reportRoot = '/reporting';

    const handleReportSelected = useCallback(
        (selectedOptions: SelectOption[]) => {
            const selected: string = selectedOptions?.map((selectedOption) => selectedOption.id?.toString() ?? '')?.[0];

            if (setReportType) {
                let url = `${reportRoot}/${selected}`;
                if (selected !== REPORT_TYPE.longitudinal && activityId) {
                    url = url.concat('/' + activityId);
                    if (selected === REPORT_TYPE.athlete) {
                        url = url.concat('/athlete');
                    }
                }
                history.push(url);
                setReportType(selected);
                if ([REPORT_TYPE.status, REPORT_TYPE.longitudinal, REPORT_TYPE.weekly].includes(selected)) {
                    modifySearchParams(DATE_TO_PARAM, undefined);
                    modifySearchParams(DATE_FROM_PARAM, undefined);
                    modifySearchParams('tags', undefined);
                }
            }
        },
        [activityId, history, modifySearchParams, setReportType],
    );

    const defaultOption = useMemo(() => {
        return reportOptions.find((option) => option.id === reportType);
    }, [reportOptions, reportType]);

    /**
     * To override the style of the select menu 'paper' element:
     * we cannot use the parent id to get it, because it is rendered in a 'portal';
     * we cannot use the generated className because it may vary;
     * we can access all referee portals:
     * as item 0 is in the right side of the navbar,
     * we get item 1, then style its div child
     */
    useEffect(() => {
        const menuObserver = new MutationObserver(() => {
            const portals = document.querySelectorAll('referee-portal');
            const paperElement = portals[1]?.firstChild;
            if (paperElement instanceof HTMLElement) {
                paperElement.style.maxHeight = 'none';
            }
        });
        /**
         * The MutationObserver interface provides the ability to watch for changes being made to the DOM tree.
         * https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
         * */
        menuObserver.observe(document.body, { childList: true, subtree: true });

        return () => menuObserver.disconnect();
    }, []);

    return (
        <ThemeProvider theme={'dark'}>
            <Select
                testId={'report-type-filter'}
                menuItems={reportOptions}
                defaultOption={defaultOption}
                onSelectItem={(selectedOptions) => {
                    flushSync(() => {
                        // we need to guarantee that this happens before the next report loads
                        // additionally, this must be an invalid index, not an empty array
                        modifySearchParams('activities', '-1');
                        modifySearchParams('date-to', undefined);
                        modifySearchParams('date-from', undefined);
                    });

                    handleReportSelected(selectedOptions);
                }}
                inputValueFormat={() => {
                    return __('reporting.reporting');
                }}
                style={{
                    width: '100%',
                    fontFamily: 'Mark Pro',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: isActive ? 'bold' : 400,
                    letterSpacing: 0,
                    marginRight: isActive ? '-36px' : '-28px',
                    display: 'flex',
                    marginLeft: '9px',
                    paddingRight: '22px',
                }}
                size="small"
                hideSelectedCheckmark={!isActive}
                isBorderless
                key={JSON.stringify(reportType)}
            />
        </ThemeProvider>
    );
};

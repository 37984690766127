import { StatRow, StatsApi, StatsRequest } from 'oas';
import { UseQueryResult } from '@tanstack/react-query';
import { useApiQuery } from '../use-api-query';
import { transformStats } from './transform';
import { DEFAULT_BENCHMARK_STATS, REPORTING } from '../../reporting/constants';
import { ONE_HOUR } from 'shared';
import { clone } from 'lodash';
import { useAverageSet } from '../../reporting/context/average-set-context';
import { useMemo } from 'react';
import { StatType } from '../../reporting/types';

export interface StatsQuery {
    statsRequest: StatsRequest;
    statType: StatType;
}
export const useGetStats = (parameters: StatsQuery): UseQueryResult<StatRow[]> => {
    const requestParameters = Object.keys(DEFAULT_BENCHMARK_STATS) // required for volume, intensity calculations
        .concat(REPORTING.duration) // required for intensity calculations
        .concat(parameters.statsRequest.parameters ?? []) // add additional params
        .filter((k) => !['volume', 'overall', 'intensity'].includes(k)); // remove unnecessary params if passed in
    return useApiQuery(
        [
            'stats',
            ...(parameters.statsRequest.parameters?.sort() ?? []), // sort improve cache hit
            ...(parameters.statsRequest.filters ?? []),
            ...(parameters.statsRequest.group_by ?? []),
        ],
        (apiConfig, axiosInstance) =>
            new StatsApi(apiConfig, '', axiosInstance)
                .postStats({ ...parameters.statsRequest, parameters: requestParameters }, {})
                .then((response) => {
                    return response;
                }),
        {
            staleTime: ONE_HOUR,
        },
    );
};

export const useGetReportingStats = (
    parameters: StatsQuery,
    postTransform?: (stats: StatRow[]) => StatRow[],
    recalculateLoadScores?: boolean,
): UseQueryResult<StatRow[]> => {
    const response = useGetStats(parameters);
    const averageSet = useAverageSet();

    return useMemo(() => {
        if (response.data) {
            // clone response data to ensure not mutating original data
            const result = transformStats(clone(response.data), averageSet, parameters.statType);
            const res = postTransform ? postTransform(result) : result;
            const data = recalculateLoadScores ? transformStats(clone(res), averageSet, parameters.statType) : res;
            return {
                ...response,
                data,
            };
        }

        return response;
    }, [response, averageSet, parameters.statType, postTransform, recalculateLoadScores]);
};

export const useGetStatsInfo = (req: StatsRequest, parameters: string[]): UseQueryResult<StatRow[]> => {
    return useApiQuery(
        ['stats', ...parameters.sort(), req],
        (apiConfig, axiosInstance) =>
            new StatsApi(apiConfig, '', axiosInstance)
                .postStats({ ...req, parameters: parameters }, {})
                .then((response) => {
                    return response;
                }),
        {
            staleTime: ONE_HOUR,
        },
    );
};

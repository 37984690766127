import { SideMenu, Spinner, Typography } from '@SBGSports/referee-react';
import { TfActivity, useDefaultTeamSeasonDates, useGetActivities } from 'api';
import { subDays } from 'date-fns';
import { useTranslations } from 'i18n';
import { cloneDeep, isEqual } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { ActivitiesSortEnum, Activity } from 'oas';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ACTIONS, EVENTS } from '../../analytics/analytics-events';
import { DATE_FNS_CASUAL_DATE_FORMAT, DATE_FNS_SHORT_DATE_TIME_FORMAT } from '../constants';
import {
    getEndOfDay,
    getNextDayOccurence,
    getSecondsDurationBetweenDates,
    getStartOfDay,
    MONDAY,
    useDateFormatter,
} from '../utils';
import { useActivityListHeightObserver, useLoadMoreObserver } from './activity-side-menu-custom-hook';
import { ActivitySideMenuFilters } from './activity-side-menu-filters';
import styles from './activity-side-menu.module.css';
import { useDebouncedCallback } from 'reporting/hooks/useDebouncedCallback';
import { useSideMenuFilterState } from './use-side-menu-filter-state';
import { REPORT_TYPE } from 'reporting/constants';
import Highcharts from 'highcharts/highstock';
import { useModifySearchParameters } from 'reporting/hooks/url-manipulation';
import { Tag } from '@SBGSports/catapult-core';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

interface MultiActivitySideMenuProps {
    selectedActivities: TfActivity[];
    setSelectedActivities: (activities: TfActivity[]) => void;
    activitiesList: TfActivity[];
    setActivitiesList: (activities: TfActivity[]) => void;
    pageSize: number;
    customContent?: React.ReactElement;
    defaultExpanded?: boolean;
    setIsSideMenuOpen: (isSideMenuOpen: boolean) => void;
    customFooter?: JSX.Element;
    reportType?: typeof REPORT_TYPE[keyof typeof REPORT_TYPE];
    weeksToPreselect?: number;
    setActivitiesLoading?: (l: boolean) => void;
}

const DEFAULT_MAX_SELECTION = 100;
const DEFAULT_MATCH_DAY_SELECTION = 7;

const getDateForQuery = (date: Date | undefined, shouldNullDate: boolean) => {
    return shouldNullDate || !date ? undefined : date.getTime() / 1000;
};

export const MultiActivitySideMenu: React.FC<React.PropsWithChildren<MultiActivitySideMenuProps>> = (props) => {
    const {
        customContent,
        pageSize,
        defaultExpanded = true,
        selectedActivities,
        setSelectedActivities,
        setIsSideMenuOpen,
        customFooter,
        activitiesList,
        setActivitiesList,
        reportType,
        weeksToPreselect,
        setActivitiesLoading,
    } = props;

    const { textFilter, setTextFilter, dateFilters, setDateFilters, tagFilters, setTagFilters } =
        useSideMenuFilterState();

    // Local state
    const [dateRangePlaceholder, setDateRangePlaceholder] = useState('');
    const [selectedItems, setSelectedItems] = useState<TfActivity[]>(selectedActivities ?? []);
    const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
    const modifySearchParams = useModifySearchParameters();
    const [indicesLoaded, setIndicesLoaded] = useState<boolean>(false);

    const seasonData = useDefaultTeamSeasonDates();

    //Ref
    const shouldAutomaticallyPreselect = useRef(true);
    const { search } = useLocation();

    const infiniteQuery = useGetActivities(
        pageSize,
        ActivitiesSortEnum.StartTimeDesc,
        0,
        ['activity_athletes'],
        getDateForQuery(getStartOfDay(dateFilters.from), false),
        getDateForQuery(getEndOfDay(dateFilters.to), false),
        textFilter,
        {
            getNextPageParam: (lastPage: Activity[], pages: Activity[][]) => {
                return lastPage.length >= pageSize ? pages.length + 1 : undefined;
            },
        },
        tagFilters,
    );
    const { observerElem } = useLoadMoreObserver(infiniteQuery);
    const formatDate = useDateFormatter();
    const { __ } = useTranslations();

    // Variables
    const flattenedResults = infiniteQuery.data?.pages.flatMap((page) => page);

    useEffect(() => {
        if (activitiesList.length !== flattenedResults?.length) {
            setActivitiesList(flattenedResults ?? []);
        }
    }, [activitiesList.length, flattenedResults, setActivitiesList]);

    const updateSelectedActivitiesDebounced = useDebouncedCallback(
        (sortedSelectedActivities: TfActivity[]) => {
            setSelectedActivities?.(sortedSelectedActivities);
        },
        [setSelectedActivities],
        1500,
    );

    const updateSelectedActivities = useCallback(
        (selectedActivitiesUnsorted: TfActivity[]) => {
            const activitiesSorted = selectedActivitiesUnsorted?.sort((first, second) =>
                getSecondsDurationBetweenDates(first.start_time, second.start_time),
            );
            setSelectedItems(activitiesSorted);

            // Call the debounced callback function with the updated selection
            updateSelectedActivitiesDebounced?.(activitiesSorted);
        },
        [updateSelectedActivitiesDebounced],
    );

    useEffect(() => {
        setActivitiesLoading?.(infiniteQuery.isLoading);
    }, [infiniteQuery.isLoading, setActivitiesLoading]);

    // Reset preselection flag on report type change
    useEffect(() => {
        if (selectedActivities.length === 0) {
            shouldAutomaticallyPreselect.current = true;
        }
    }, [selectedActivities]);

    //Handle preselecting activities
    useEffect(() => {
        const hasResults = (infiniteQuery.data?.pages?.[0]?.length ?? 0) > 0;
        if (!hasResults || !shouldAutomaticallyPreselect.current) {
            return;
        }

        let newSelectedActivities: TfActivity[] = [];
        let newDateRangePlaceholder: string | undefined;

        if (reportType === REPORT_TYPE.longitudinal || reportType === REPORT_TYPE.weekly) {
            const scope = weeksToPreselect && weeksToPreselect > 0 ? weeksToPreselect : 1;
            const mostRecentActivityDate = infiniteQuery.data?.pages[0]?.[0]?.start_time ?? new Date();
            const morningMostRecentActivityDate = getStartOfDay(mostRecentActivityDate) ?? mostRecentActivityDate;
            let weeksAgo = subDays(morningMostRecentActivityDate, scope * 7);

            if (reportType === REPORT_TYPE.weekly) {
                const weekStartDay =
                    seasonData?.length !== 0 ? new Date((seasonData?.[0].value ?? 0) * 1000).getDay() : MONDAY;
                weeksAgo = getNextDayOccurence(
                    weekStartDay,
                    moment(morningMostRecentActivityDate).subtract(weeksToPreselect, 'weeks').toDate(),
                );
            }

            newSelectedActivities =
                flattenedResults
                    ?.filter((activity) => activity.start_time > weeksAgo)
                    .slice(0, DEFAULT_MAX_SELECTION) ?? [];
            newDateRangePlaceholder = `${formatDate(weeksAgo, DATE_FNS_CASUAL_DATE_FORMAT)} - ${formatDate(
                mostRecentActivityDate,
                DATE_FNS_CASUAL_DATE_FORMAT,
            )}`;
        } else {
            newSelectedActivities = cloneDeep(flattenedResults)?.slice(0, DEFAULT_MATCH_DAY_SELECTION) ?? [];
        }

        if (!isEqual(selectedActivities, newSelectedActivities)) {
            updateSelectedActivities(newSelectedActivities);
            if (newDateRangePlaceholder) {
                setDateRangePlaceholder(newDateRangePlaceholder);
            }
            shouldAutomaticallyPreselect.current = false;
        }
    }, [
        flattenedResults,
        formatDate,
        infiniteQuery.data?.pages,
        reportType,
        selectedActivities,
        weeksToPreselect,
        shouldAutomaticallyPreselect,
        updateSelectedActivities,
        seasonData,
    ]);

    useEffect(() => {
        if (activitiesList[0]) {
            modifySearchParams('date-to', new Date(activitiesList?.[0]?.start_time).toISOString().split('T')[0]);
        }

        modifySearchParams('activities', selectedIndices.join(','));
    }, [selectedIndices, modifySearchParams, activitiesList]);

    // when filters change, force selected indices to re-settle if they are invalid
    useEffect(() => {
        const selectedQuery = new URLSearchParams(search).get('activities')?.split(',');

        if (selectedQuery) {
            for (let x = 0; x < selectedQuery?.length; x++) {
                const idx = parseInt(selectedQuery[x], 10);

                if (idx > activitiesList.length - 1 || selectedQuery.length < selectedActivities.length) {
                    const indices = activitiesList
                        .map((selected) => selectedActivities.findIndex((a) => a.id === selected.id))
                        .filter((i) => i !== -1);
                    setSelectedIndices(indices);
                    break;
                }
            }
        }
    }, [selectedActivities, activitiesList]); // eslint-disable-line

    useEffect(() => {
        const selectedQuery = new URLSearchParams(search).get('activities')?.split(',');
        const idxs: number[] = [];

        if (selectedQuery) {
            if (!indicesLoaded) {
                for (let i = 0; i < selectedQuery?.length; i++) {
                    const idx = parseInt(selectedQuery[i], 10);

                    if (idx > -1) {
                        idxs.push(idx);
                    }
                }

                setSelectedIndices(idxs);

                if (activitiesList.length > 0) {
                    const items =
                        activitiesList?.filter((_tfActivity: TfActivity, i: number) =>
                            idxs.filter((idx) => idx <= activitiesList.length - 1).some((j) => i === j),
                        ) ?? [];

                    // fallback if no valid activities in URL, otherwise report won't load
                    if (items.length === 0) {
                        items.push(activitiesList[0]);
                        setSelectedIndices([0]);
                    }

                    setIndicesLoaded(true);
                    updateSelectedActivities(items);
                }
            }
        } else if (selectedActivities.length > 0) {
            const indices = activitiesList
                .map((selected) => selectedActivities.findIndex((a) => a.id === selected.id))
                .filter((i) => i !== -1);
            setSelectedIndices(indices);
        }
    }, [selectedActivities, updateSelectedActivities, indicesLoaded, activitiesList, search]);

    const { activityListRef, sideMenuFooterRef } = useActivityListHeightObserver();

    const handleMultiActivitySelection = (activity: TfActivity, index: number) => {
        const selectionType = 'multiple';
        const shouldDeselect = isIncludedInCurrentlySelected(activity);
        const indices = [...selectedIndices];

        if (shouldDeselect) {
            const i = indices.indexOf(index);

            if (i >= 0) {
                indices.splice(i, 1);
            }
        } else {
            indices.push(index);
        }

        setSelectedIndices(indices);

        mixpanel.track(EVENTS.sideMenu.activitySelection, {
            activityId: activity.id,
            selectionType,
            action: shouldDeselect ? ACTIONS.deselected : ACTIONS.selected,
        });

        if (shouldDeselect) {
            selectedItems.length !== 1 && // Do not allow deselecting the last activity
                updateSelectedActivities(
                    selectedItems?.filter((tfActivity: TfActivity) => tfActivity.id !== activity.id) ?? [],
                );
        } else {
            updateSelectedActivities([...selectedItems, activity]);
        }
    };

    const isIncludedInCurrentlySelected = (activity: TfActivity): boolean => {
        return !!selectedItems?.find((tfActivity) => tfActivity.id === activity?.id);
    };

    return (
        <div
            className={styles['side-bar-container']}
            onTransitionEnd={() => {
                Highcharts.charts.forEach((c) => c?.reflow()); // ensure charts resize on side menu open shifting divs
            }}
        >
            <SideMenu
                testId={'side-bar-menu'}
                defaultExpanded={defaultExpanded}
                affixBottom={<div ref={sideMenuFooterRef}>{customFooter}</div>}
                onExpandCollapse={(sideMenuOpen) => {
                    setIsSideMenuOpen(sideMenuOpen);
                }}
            >
                <div className={styles['filter-column']}>
                    {customContent}
                    <ActivitySideMenuFilters
                        textFilter={textFilter}
                        setTextFilter={setTextFilter}
                        selectedDateRange={dateFilters}
                        setDateRange={setDateFilters}
                        tagFilters={tagFilters}
                        setTagFilters={setTagFilters}
                        dataLoading={infiniteQuery.isLoading || infiniteQuery.isFetchingNextPage}
                        dateRangePlaceholder={dateRangePlaceholder}
                        reportType={reportType}
                    />
                </div>
                <div data-testid={'activity-menu-title'} className={styles['activity-menu-title']}>
                    <Typography variant="label-1">
                        {`${__('misc.activities')} (${flattenedResults?.length ?? 0})`}
                    </Typography>
                </div>
                <div id={styles['activity-list']} ref={activityListRef}>
                    {infiniteQuery.isLoading || !indicesLoaded ? (
                        <span className={styles['centered-span']}>
                            <Spinner testId={'activity-search-spinner'} />
                        </span>
                    ) : (
                        flattenedResults?.map((activity: TfActivity, i: number) => {
                            const selectedClass = isIncludedInCurrentlySelected(activity) ? 'selected' : '';
                            const tag = activity.tags?.find((t) => t.tag_type_name === 'DayCode');
                            return (
                                <div
                                    key={activity.id}
                                    onClick={() => handleMultiActivitySelection(activity, i)}
                                    style={{ cursor: 'pointer' }}
                                    className={[styles['menu-activity'], styles[selectedClass]].join(' ')}
                                >
                                    <div className={styles['menu-activity-text']}>
                                        <Typography variant="body-1" numberOfLines={1} title={activity.name}>
                                            {activity.name}
                                        </Typography>
                                        <Typography
                                            numberOfLines={1}
                                            title={activity.name}
                                            className={styles['activity-menu-date']}
                                        >
                                            {formatDate(activity.start_time, DATE_FNS_SHORT_DATE_TIME_FORMAT)}
                                        </Typography>
                                    </div>
                                    <div className={styles['menu-activity-tag']}>
                                        {tag?.tag_name && <Tag label={tag.tag_name} className="uppercase" />}
                                    </div>
                                </div>
                            );
                        })
                    )}
                    {(infiniteQuery.isFetchingNextPage || infiniteQuery.hasNextPage) && (
                        <span className={styles['centered-span']}>
                            <Spinner ref={observerElem} />
                        </span>
                    )}
                </div>
            </SideMenu>
            {props.children}
        </div>
    );
};

import { KEY_DATE_LOCALE, KEY_DEFAULT_LANGUAGE_WEB, useGetSetting } from 'api';
import {
    endOfDay,
    format,
    formatDistanceToNow,
    getISOWeek,
    intervalToDuration,
    isValid,
    Locale,
    startOfDay,
} from 'date-fns';
import { enAU, enUS, es, fr, ja, ptBR, zhCN } from 'date-fns/locale';
import * as React from 'react';
import moment from 'moment';

export type DateFormatter = (date: Date, dateFormat?: string) => string;
export type DateFormatFunction = (date: Date, locale?: Locale) => string;

export const MONDAY = 1;

const getLocale = (language: string, dateLocale?: string): Locale | undefined => {
    switch (language) {
        case 'en':
            return dateLocale === 'American' ? enUS : enAU;
        case 'es':
            return es;
        case 'fr':
            return fr;
        case 'ja':
            return ja;
        case 'ptbr':
            return ptBR;
        case 'zh':
            return zhCN;
    }
};

/**
 * Get a function that will format a date according to user's selected language and date preference
 */
export const useDateFormatter = (formatter?: DateFormatFunction): DateFormatter => {
    const languageSetting = useGetSetting(KEY_DEFAULT_LANGUAGE_WEB);
    const dateLocaleSetting = useGetSetting(KEY_DATE_LOCALE);

    return React.useCallback(
        (date: Date, dateFormat?: string): string => {
            if (date === null || isNaN(date.valueOf())) {
                return ''; // Return an empty string or a default value if date is null or invalid
            }

            const locale = getLocale(languageSetting?.value || '', dateLocaleSetting?.value || '');

            return formatter ? formatter(date, locale) : format(date, dateFormat || '', { locale });
        },
        [dateLocaleSetting?.value, languageSetting?.value, formatter],
    );
};

export const useIntervalFormatter = (): DateFormatter =>
    useDateFormatter((date, locale) => formatDistanceToNow(date, { locale, addSuffix: true }));

/**
 * Format a duration in seconds to a string of the form h:mm:ss.
 */
export const formatDuration = (durationInSeconds: number): string => {
    const end = (durationInSeconds || 0) * 1000;
    const validatedEndDate = isValid(end) ? end : 0;

    const { days, hours, minutes, seconds } = intervalToDuration({
        start: 0,
        end: validatedEndDate,
    });

    const realHours = `${(days || 0) * 24 + (hours || 0)}`;
    const realMinutes = `${minutes || 0}`.padStart(2, '0');
    const realSeconds = `${seconds || 0}`.padStart(2, '0');

    return `${realHours}:${realMinutes}:${realSeconds}`;
};

/**
 * Get formatted duration between two UNIX timestamps
 *
 * @param startTimestamp
 * @param endTimestamp
 * @returns {string}
 */
export const getFormattedDuration = (startTimestamp: number, endTimestamp: number): string => {
    if (startTimestamp && endTimestamp) {
        return formatDuration(endTimestamp - startTimestamp);
    }
    return '';
};

/**
 * Get formatted duration between two UNIX timestamps
 */
export const getFormattedDurationBetweenDates = (startTimestamp: Date, endTimestamp: Date): string => {
    return getFormattedDuration(
        getUnixTimestamp(startTimestamp.toString()) as number,
        getUnixTimestamp(endTimestamp.toString()) as number,
    );
};

/**
 * Get duration in seconds between two UNIX timestamps
 */
export const getSecondsDurationBetweenDates = (startTimestamp: Date, endTimestamp: Date): number => {
    return Math.floor(endTimestamp.valueOf() / 1000) - Math.floor(startTimestamp.valueOf() / 1000);
};

export const getUnixTimestamp = (date: string): string | number => {
    if (date) {
        const parsed = Date.parse(date);
        if (parsed) {
            return Math.round(parsed.valueOf() / 1000);
        }

        if (Number.isInteger(date)) {
            return date;
        }
    }
    return '';
};

export const getDate = (date: Date | number | null): Date | undefined => {
    if (typeof date === 'number') {
        return new Date(date);
    }

    if (date instanceof Date) {
        return date;
    }
};

export const getAge = (dateOfBirth: Date): number => {
    const currentDate = new Date();
    const age = currentDate.getFullYear() - dateOfBirth.getFullYear();
    const monthDifference = currentDate.getMonth() - dateOfBirth.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && currentDate.getDate() < dateOfBirth.getDate())) {
        return age - 1;
    }
    return age;
};

export const parseTime = (formatted: string, baseValue: Date = new Date()): Date => {
    const ret = new Date();
    if (formatted !== '') {
        const hmsMatches = formatted.match(/[0-9:]+/);
        if (hmsMatches && hmsMatches.length) {
            const [hms] = hmsMatches;

            const [hours, minutes, seconds] = hms.split(':');
            const secondsInt = seconds ? parseInt(seconds, 10) : 0;
            const minutesInt = minutes ? parseInt(minutes, 10) : 0;
            let hoursInt = hours ? parseInt(hours, 10) : 0;

            if (hoursInt < 12 && formatted.toLowerCase().match(/p/)) {
                hoursInt += 12;
            }

            hoursInt %= 24;

            ret.setFullYear(baseValue.getFullYear(), baseValue.getMonth(), baseValue.getDate());
            ret.setHours(hoursInt, minutesInt, secondsInt, 0);
        }
    }
    return ret;
};

export const convertStatDateMomentFormat = (dateString: string, dateFormat: string): string => {
    if (!dateString || dateString === '0') {
        return 'Invalid date';
    }
    const dateParts = dateString?.split('/');
    const day = parseInt(dateParts?.[0], 10); // day
    const month = parseInt(dateParts?.[1], 10) - 1; // month, subtract 1 as index starts at 0
    const year = parseInt(dateParts?.[2], 10); // year

    const dateObject = moment({ year, month, day });

    return dateObject.format(dateFormat);
};

export const getStartOfDay = (date: Date | undefined) => {
    if (date) {
        return startOfDay(date);
    }
    return date;
};

export const getEndOfDay = (date: Date | undefined) => {
    if (date) {
        return endOfDay(date);
    }
    return date;
};

export const getWeekNumber = (date: Date): number => {
    return getISOWeek(date) ?? 0;
};

export const getDateFromTimestamp = (timeStamp: number, minusDays = 0) => {
    const date = new Date(isNaN(timeStamp) ? Date.now() : timeStamp);
    date?.setDate(date?.getDate() - minusDays);
    date?.setHours(0, 0, 0, 0);
    return date;
};

export const roundToNearest = (time: number, nearest: number) => Math.round(time / nearest) * nearest;

export const roundDateToNearestSecond = (date: Date) => new Date(Math.round(date.getTime() / 1000) * 1000);

export const getPastDate = (date: Date, subtractDays: number) => {
    const copyDate = new Date(date.getTime());
    copyDate.setDate(copyDate.getDate() - (subtractDays - 1));
    copyDate.setHours(0, 0, 0, 0);
    return copyDate;
};

export const getNextDayOccurence = (targetDay: number, now: Date, getPrevious?: boolean) => {
    const dayNow = now.getDay();
    const nextDate = new Date(now);
    let daysUntilNext = 0;

    targetDay = (targetDay + 7) % 7; // wrap back to 0 if targetDay is -1 (e.g. because we wanted one day back from sunday)

    if (targetDay === dayNow) {
        return now;
    }

    if (getPrevious) {
        daysUntilNext = (dayNow - targetDay + 7) % 7 || 7;
        nextDate.setDate(now.getDate() - daysUntilNext);
    } else {
        daysUntilNext = (targetDay - dayNow + 7) % 7 || 7;
        nextDate.setDate(now.getDate() + daysUntilNext);
    }

    return nextDate;
};

export const capitalise = (s: unknown): string => {
    if (typeof s !== 'string') {
        return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const countMax = (count: number, max: number): string => {
    return count > max ? `${max}+` : `${count}`;
};

export const arrayToCsv = (data: string[][]): string => {
    return data
        .map(
            (row) =>
                row
                    .map(String) // convert every value to String
                    .map((v) => v.replaceAll('"', '""')) // escape double quotes
                    .map((v) => `"${v}"`) // quote value
                    .join(','), // comma-separated
        )
        .join('\r\n'); // rows starting on new lines
};

export const reverseAndShortenName = (name: string, shortenFirst?: boolean): string => {
    if (!name) {
        return '';
    }

    const newName = name.split(/ (.*)/, 2);
    return newName[1] + ', ' + (shortenFirst ? newName[0].charAt(0).toUpperCase() + '.' : newName[0]);
};

export const reverseName = (displayName: string): string => {
    if (!displayName) {
        return '';
    }

    const playerNameArray = displayName.toString().split(' ');

    if (playerNameArray.length > 1) {
        return playerNameArray.pop() + ', ' + playerNameArray.join(' ');
    }

    return displayName;
};

export const displayNameAsLastCommaFirst = (
    last_name: string,
    first_name: string,
    upper_case = false,
    first_inital = false,
): string => {
    let combinedName = `${last_name}, ${first_name}`;

    if (first_inital) {
        combinedName = `${last_name}, ${first_name.charAt(0)}`;
    }

    if (upper_case) {
        combinedName = combinedName.toUpperCase();
    }

    return combinedName;
};

export const isValidEmail = (email?: string): boolean => {
    return /\S+@\S+\.\S+/.test(email || '');
};

export const strToInt = (str: string, max: number, min: number): number => {
    let result = 0;
    for (let i = 0; i < str.length; i++) {
        result = result + str.charCodeAt(i);
    }

    return (result % (max - min)) + min;
};

export const recapitalizeString = (val: string): string => {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
};

import { DateRangePicker, FormControl, SearchInput, Select, SelectOption, Spinner } from '@SBGSports/referee-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { subDays } from 'date-fns';
import { useTranslations } from 'i18n';
import mixpanel from 'mixpanel-browser';
import { EVENTS } from '../../analytics/analytics-events';
import { useFetchTags } from 'reporting/hooks/use-fetch-tag-options';
import { useDebouncedCallback } from 'reporting/hooks/useDebouncedCallback';
import { DATE_FROM_PARAM, DATE_TO_PARAM, SEARCH_PARAM, TAGS_PARAM, useQueryParams } from 'shared/use-query-params';
import { useDateFormatter } from 'shared/utils';
import { DATE_FNS_GLOBAL_FORMAT } from 'shared/constants';
import { REPORT_TYPE } from 'reporting/constants';

interface ActivitySideMenuFiltersProps {
    textFilter?: string;
    setTextFilter: (filter: string) => void;
    selectedDateRange: DateRange;
    setDateRange: (dates: DateRange) => void;
    tagFilters?: string[];
    setTagFilters: (tagFilters: string[]) => void;
    dataLoading: boolean;
    dateRangePlaceholder?: string;
    reportType?: typeof REPORT_TYPE[keyof typeof REPORT_TYPE];
}

export interface DateRange {
    from?: Date;
    to?: Date;
}

export const ActivitySideMenuFilters: React.FC<ActivitySideMenuFiltersProps> = (props) => {
    const {
        textFilter,
        setTextFilter,
        selectedDateRange,
        setDateRange,
        tagFilters,
        setTagFilters,
        dataLoading,
        dateRangePlaceholder,
        reportType,
    } = props;

    // hooks
    const { __ } = useTranslations();
    const filterTags = useFetchTags();
    const { removeQueryParam, updateQueryParam } = useQueryParams();
    const formatDate = useDateFormatter();

    // local state
    const [searchText, setSearchText] = useState<string>(textFilter ?? '');
    const [syncText, setSyncText] = useState<string>(textFilter ?? '');

    const searchTextChanged = useRef(false);

    // variables
    const dateRangePresets = {
        LAST_7_DAYS: {
            from: subDays(new Date(), 7),
            label: __('misc.last_range_days', { range: '7' }),
            to: new Date(),
        },
        LAST_30_DAYS: {
            from: subDays(new Date(), 30),
            label: __('misc.last_range_days', { range: '30' }),
            to: new Date(),
        },
    };

    const debouncedTextFilter = useDebouncedCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchText(event.target.value);
            updateQueryParam(SEARCH_PARAM, event.target.value);
        },
        [setSearchText],
        1000,
    );

    // update filters for text search
    useEffect(() => {
        setTextFilter(searchText ?? '');
        const defaultDateRange = { from: null, to: null } as unknown as DateRange;
        if (searchTextChanged.current) {
            setDateRange(defaultDateRange);
        }

        mixpanel.track(EVENTS.sideMenu.activitySearch, {
            searchDateRange: defaultDateRange,
            searchText: searchText,
            searchFrom: 'Side Menu',
            reportType: reportType,
        });
    }, [searchText, setDateRange, setTextFilter, reportType]);

    const handleSelectItem = (selectedOptions: SelectOption[]) => {
        const selected: string[] = selectedOptions?.map((selectedOption) => selectedOption.value?.toString() ?? '');
        mixpanel.track(EVENTS.sideMenu.activitySearch, {
            selectedTag: selected,
            reportType: reportType,
        });
        setTagFilters([...selected]);
        updateQueryParam(TAGS_PARAM, selected.join(','));
    };

    const filteredTagsByQueryParam = useMemo(() => {
        return filterTags?.map((tag) => {
            return {
                ...tag,
                isSelected: tagFilters?.includes(tag.value),
            };
        });
    }, [filterTags, tagFilters]);

    if (filteredTagsByQueryParam?.filter((tag) => tag.isDisabled)?.length === 0) {
        return <Spinner testId="side-menu-filter-spinner" />;
    }

    return (
        <>
            <FormControl label={`${__('misc.filter_by')} ${__('misc.name')}`} fullWidth>
                <SearchInput
                    data-testid="activityNameSearchInput"
                    placeholder={__('league_migration.search_activities')}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        searchTextChanged.current = true;
                        removeQueryParam(DATE_FROM_PARAM);
                        removeQueryParam(DATE_TO_PARAM);
                        setSyncText(event.target.value);
                        debouncedTextFilter(event);
                    }}
                    value={syncText}
                />
            </FormControl>
            <FormControl label={`${__('misc.filter_by')} ${__('misc.tag')}`} fullWidth>
                <Select
                    menuItems={filteredTagsByQueryParam}
                    placeholder={`${__('misc.select')} ${__('misc.tags')}`}
                    isMultiselect
                    maxDisplayValues={1}
                    onSelectItem={handleSelectItem}
                    deselectAllOnClear
                />
            </FormControl>
            <FormControl label={`${__('misc.filter_by')} ${__('misc.date')}`} fullWidth>
                <DateRangePicker
                    placeholder={dateRangePlaceholder}
                    value={selectedDateRange}
                    onChange={(dates) => {
                        mixpanel.track(EVENTS.sideMenu.activitySearch, {
                            searchDateRange: dates,
                            searchText: searchText,
                            searchFrom: 'Side Menu',
                        });
                        setDateRange(dates);
                        updateQueryParam(
                            DATE_FROM_PARAM,
                            dates.from ? formatDate(dates.from, DATE_FNS_GLOBAL_FORMAT) : 'null',
                        );
                        updateQueryParam(
                            DATE_TO_PARAM,
                            dates.to ? formatDate(dates.to, DATE_FNS_GLOBAL_FORMAT) : 'null',
                        );
                    }}
                    min={new Date(2010, 0, 1)}
                    max={new Date()}
                    presets={dateRangePresets}
                    disabled={dataLoading}
                />
            </FormControl>
        </>
    );
};

import { createContext, ReactNode, useContext } from 'react';
import { AverageSetsModel } from '../types';
import { AverageSet, LiveInfoDistanceUnitEnum } from 'oas';
import { getDefaultBenchmark } from '../helpers';
import { MATCH_DAY } from '../constants';

interface AverageSetProviderProps {
    averageSets: AverageSetData | undefined;
    children: ReactNode;
}

export interface AverageSetData {
    averageSets: AverageSetsModel;
    averageSetsInfo: AverageSet[];
}
export const AverageSetContext = createContext<AverageSetData | undefined>(undefined);

export const AverageSetProvider = (props: AverageSetProviderProps) => {
    return <AverageSetContext.Provider value={props.averageSets}>{props.children}</AverageSetContext.Provider>;
};

export const useAverageSet = () => {
    const context = useContext(AverageSetContext);
    return (
        context?.averageSets ?? {
            teamAverageSet: undefined,
            positionAverageSet: undefined,
            athleteAverageSet: undefined,
            defaultBenchmark: getDefaultBenchmark(LiveInfoDistanceUnitEnum.Kilometers),
            comparison: MATCH_DAY,
        }
    );
};

export const useAverageSetInfo = () => {
    const context = useContext(AverageSetContext);
    return context?.averageSetsInfo ?? [];
};

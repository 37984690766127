import { ActivityAthlete, Team, TeamsApi } from 'oas';
import { mockResponse, useApiQuery } from '../use-api-query';
import { UseQueryResult } from '@tanstack/react-query';

export const useGetTeams = (...parameters: Parameters<TeamsApi['getAllTeams']>): UseQueryResult<Team[]> => {
    return useApiQuery(
        ['teams', parameters],
        (apiConfig, axiosInstance) => new TeamsApi(apiConfig, '', axiosInstance).getAllTeams(...parameters),
        {
            staleTime: 3600000,
        },
    );
};

export const useDefaultTeam = (settings?: 'settings'[]): Team | undefined => {
    const teams = useGetTeams(settings || []);

    return teams.data?.find((team) => team.is_default);
};

export const useGetTeam = (teamId?: string, includeSettings?: boolean): Team | undefined => {
    const teams = useGetTeams(includeSettings ? ['settings'] : []);

    if (!teamId) {
        return undefined;
    }

    return teams.data?.find((team) => team.id === teamId);
};

export const useTeamSeasonDates = (teamId: string): { key: string; value: number }[] => {
    const team = useGetTeam(teamId, true);

    // season dates are sorted by date they were entered
    // so we have to manually guarantee that years are in order
    const seasonData = team?.settings
        ?.filter((s) => s.key.includes('SeasonStart'))
        .sort((a, b) => {
            const yearA = parseInt(a.key.match(/^(\d{4})/)?.[1] || '0', 10);
            const yearB = parseInt(b.key.match(/^(\d{4})/)?.[1] || '0', 10);
            return yearB - yearA;
        });

    if (seasonData) {
        return seasonData;
    } else {
        return [];
    }
};

export const useDefaultTeamSeasonDates = (): { key: string; value: number }[] => {
    const defaultTeam = useDefaultTeam();
    const seasonDates = useTeamSeasonDates(defaultTeam ? defaultTeam.id : '');

    return seasonDates;
};

export const useGetAthletesForTeam: (
    ...parameters: Parameters<TeamsApi['getAthletesForTeam']>
) => UseQueryResult<Array<ActivityAthlete>> = (id, options = {}) => {
    return useApiQuery(
        ['teams', id, 'athletes'],
        (apiConfig, axiosInstance) => {
            return id !== ''
                ? new TeamsApi(apiConfig, '', axiosInstance).getAthletesForTeam(id, options)
                : mockResponse([]);
        },
        {
            staleTime: 3600000,
        },
    );
};

import * as React from 'react';
import { Text } from '@SBGSports/catapult-core';

interface ErrorLayoutProps {
    code: string;
    title: string;
    message?: React.ReactElement | string;
}

export const ErrorDisplay: React.FC<ErrorLayoutProps> = ({ code, title, message }) => {
    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ padding: '1rem' }}>
                <div style={{ display: 'flex', gap: '8px', flexDirection: 'column', maxWidth: '700px' }}>
                    <Text align="center" variant="heading-large">
                        {code}
                    </Text>
                    <Text align="center" variant="heading-medium">
                        {title}
                    </Text>
                    <Text align="center">{message}</Text>
                </div>
            </div>
        </div>
    );
};

import { SideMenu, Spinner, Typography } from '@SBGSports/referee-react';
import { TfActivity, useGetActivities } from 'api';
import { useTranslations } from 'i18n';
import mixpanel from 'mixpanel-browser';
import { ActivitiesSortEnum, Activity } from 'oas';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { EVENTS } from '../../analytics/analytics-events';
import { DATE_FNS_SHORT_DATE_TIME_FORMAT } from '../constants';
import { getEndOfDay, getStartOfDay, useDateFormatter } from '../utils';
import { useActivityListHeightObserver, useLoadMoreObserver } from './activity-side-menu-custom-hook';
import { ActivitySideMenuFilters, DateRange } from './activity-side-menu-filters';
import styles from './activity-side-menu.module.css';
import Highcharts from 'highcharts/highstock';
import { REPORT_TYPE, REPORTING_STATUS_VIEW_PATH } from 'reporting/constants';
import { Tag } from '@SBGSports/catapult-core';

interface SingleActivitySideMenuProps {
    viewPath: string;
    selectedActivity: string;
    setSelectedActivity: (id: string) => void;
    pageSize: number;
    customContent?: React.ReactElement;
    defaultExpanded?: boolean;
    setIsSideMenuOpen: (isSideMenuOpen: boolean) => void;
    customFooter?: JSX.Element;
    disabledExpandCollapse?: boolean;
    reportType?: typeof REPORT_TYPE[keyof typeof REPORT_TYPE];
}

const getDateForQuery = (date: Date | undefined, shouldNullDate: boolean) => {
    return shouldNullDate || !date ? undefined : date.getTime() / 1000;
};

export const SingleActivitySideMenu: React.FC<React.PropsWithChildren<SingleActivitySideMenuProps>> = (props) => {
    const {
        viewPath,
        selectedActivity,
        setSelectedActivity,
        customContent,
        pageSize,
        defaultExpanded = true,
        setIsSideMenuOpen,
        customFooter,
        disabledExpandCollapse,
        reportType,
    } = props;

    // Local state
    const [textFilter, setTextFilter] = useState<string>();
    const [dateFilters, setDateFilters] = useState<DateRange>({ from: null, to: null } as unknown as DateRange);
    const [tagFilters, setTagFilters] = useState<string[]>();

    // Hooks
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch();

    const path = viewPath?.replaceAll('/', '');
    const pathName = location.pathname.replaceAll('/', '');

    const isPathMatched = pathName === path?.replaceAll('/', '');
    const isActivityNotInUrl = !pathName.includes(selectedActivity ?? '');
    const isActivityNotSelected = !selectedActivity;

    const shouldSetSingleDefault = (!selectedActivity && isPathMatched) || isActivityNotInUrl || isActivityNotSelected;

    const infiniteQuery = useGetActivities(
        pageSize,
        ActivitiesSortEnum.StartTimeDesc,
        0,
        undefined,
        getDateForQuery(getStartOfDay(dateFilters.from), shouldSetSingleDefault),
        getDateForQuery(getEndOfDay(dateFilters.to), shouldSetSingleDefault),
        textFilter,
        {
            getNextPageParam: (lastPage: Activity[], pages: Activity[][]) => {
                return lastPage.length >= pageSize ? pages.length + 1 : undefined;
            },
        },
        tagFilters,
    );
    const { observerElem } = useLoadMoreObserver(infiniteQuery);
    const formatDate = useDateFormatter();
    const { __ } = useTranslations();

    // Variables
    const flattenedResults = useMemo(() => {
        return infiniteQuery.data?.pages.flatMap((page) => page);
    }, [infiniteQuery.data?.pages]);

    // Handle default selection if no current selection or activityId in path
    useEffect(() => {
        const hasResults: boolean = (infiniteQuery.data?.pages?.[0]?.length ?? 0) > 0;
        if (shouldSetSingleDefault) {
            setDateFilters({ from: null, to: null } as unknown as DateRange);
            if (hasResults) {
                const defaultActivitySelection = infiniteQuery.data?.pages[0][0].id ?? '';
                history.replace(`${viewPath}/${defaultActivitySelection}`);
                setSelectedActivity(defaultActivitySelection);
            }
        }
    }, [history, infiniteQuery.data?.pages, setSelectedActivity, shouldSetSingleDefault, viewPath]);

    const { activityListRef, sideMenuFooterRef } = useActivityListHeightObserver();

    const handleSingleActivitySelection = (activityId: string) => {
        mixpanel.track(EVENTS.sideMenu.activitySelection, { activityId, type: 'single' });
        setSelectedActivity(activityId);
    };

    const getPath = (activityId: string) => {
        const currentPath = history.location.pathname;
        const regex = new RegExp(viewPath ?? '', 'g');
        const splitPath = currentPath.split(regex);
        if (splitPath.length > 1) {
            const pathParams = splitPath[1].split('/').filter((element) => element !== '');
            pathParams[0] = activityId;
            const newPath = pathParams.join('/');
            return `${viewPath}/${newPath}`;
        } else {
            return currentPath;
        }
    };

    if (
        match.url.includes(REPORTING_STATUS_VIEW_PATH) ||
        (viewPath === REPORTING_STATUS_VIEW_PATH && reportType === REPORT_TYPE.status)
    ) {
        return <>{props.children}</>;
    }

    return (
        <div
            className={styles['side-bar-container']}
            onTransitionEnd={() => {
                Highcharts.charts.forEach((c) => c?.reflow()); // ensure charts resize on side menu open shifting divs
            }}
        >
            <SideMenu
                testId={'side-bar-menu'}
                defaultExpanded={defaultExpanded}
                affixBottom={<div ref={sideMenuFooterRef}>{customFooter}</div>}
                onExpandCollapse={(sideMenuOpen) => {
                    setIsSideMenuOpen(sideMenuOpen);
                }}
                disabledExpandCollapse={disabledExpandCollapse}
            >
                <div className={styles['filter-column']}>
                    {customContent}
                    <ActivitySideMenuFilters
                        setTextFilter={setTextFilter}
                        selectedDateRange={dateFilters}
                        setDateRange={setDateFilters}
                        setTagFilters={setTagFilters}
                        dataLoading={infiniteQuery.isLoading || infiniteQuery.isFetchingNextPage}
                    />
                </div>
                <div data-testid={'activity-menu-title'} className={styles['activity-menu-title']}>
                    <Typography variant="label-1">
                        {`${__('misc.activities')} (${flattenedResults?.length ?? 0})`}
                    </Typography>
                </div>
                <div id={styles['activity-list']} ref={activityListRef}>
                    {infiniteQuery.isLoading ? (
                        <span className={styles['centered-span']}>
                            <Spinner />
                        </span>
                    ) : (
                        flattenedResults?.map((activity: TfActivity) => {
                            const selectedClass =
                                selectedActivity === activity.id || location.pathname.split('/').pop() === activity.id
                                    ? 'selected'
                                    : '';
                            const tag = activity.tags?.find((t) => t.tag_type_name === 'DayCode');
                            return (
                                <Link
                                    key={activity.id}
                                    to={getPath(activity.id)}
                                    onClick={() => handleSingleActivitySelection(activity.id)}
                                >
                                    <div className={[styles['menu-activity'], styles[selectedClass]].join(' ')}>
                                        <div className={styles['menu-activity-text']}>
                                            <Typography variant="body-1" numberOfLines={1} title={activity.name}>
                                                {activity.name}
                                            </Typography>
                                            <Typography
                                                numberOfLines={1}
                                                title={activity.name}
                                                className={styles['activity-menu-date']}
                                            >
                                                {formatDate(activity.start_time, DATE_FNS_SHORT_DATE_TIME_FORMAT)}
                                            </Typography>
                                        </div>
                                        <div className={styles['menu-activity-tag']}>
                                            {tag?.tag_name && <Tag label={tag.tag_name} className="uppercase" />}
                                        </div>
                                    </div>
                                </Link>
                            );
                        })
                    )}
                    {(infiniteQuery.isFetchingNextPage || infiniteQuery.hasNextPage) && (
                        <span className={styles['centered-span']}>
                            <Spinner ref={observerElem} />
                        </span>
                    )}
                </div>
            </SideMenu>
            {props.children}
        </div>
    );
};

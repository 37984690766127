import * as React from 'react';
import { useAuth } from 'auth';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { MiniLayout } from 'layout';
import { Helmet } from 'react-helmet-async';
import { appTitle } from 'shared';
import { Error404 } from 'pages';
import { Spinner } from '@SBGSports/referee-react';

const Auth: React.FC = () => {
    const auth = useAuth();
    const calledOnce = React.useRef(false);
    const [invalidToken, setInvalidToken] = React.useState(false);
    const { page, token: pathToken } = useParams<{ page?: string; token?: string }>();

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const token = pathToken || params.get('t') || params.get('m') || '';

    const validPaths = ['registration', 'resethandler', 'sso', 'share'];
    const isValidPath = page && validPaths.includes(page as typeof validPaths[number]);

    // Handle final redirect, 'sso' or 'share' have no direct redirect
    let redirectTo = '/';
    if (page === 'registration') {
        redirectTo = '/registration';
    } else if (page === 'resethandler') {
        redirectTo = '/login/reset-password';
    }

    React.useEffect(() => {
        if (calledOnce.current || !auth.csrfAuthd) {
            return;
        }
        calledOnce.current = true;

        (async () => {
            try {
                if (!auth.user) {
                    if (page === 'share') {
                        const finalUrl: string = await auth.loginWithSharedToken(decodeURIComponent(token));
                        //store token in session storage to use in mixpanel tracking later
                        sessionStorage.setItem('shared-link-token', JSON.stringify({ token, url: finalUrl }));
                        window.location.assign(finalUrl);
                    } else {
                        await auth.loginWithToken(token);
                    }
                } else {
                    auth.checkAuthentication();
                }
            } catch (e) {
                setInvalidToken(true);
            }
        })();
    }, [auth, page, token]);

    if (!isValidPath) {
        return <Error404 />;
    }

    return (
        <>
            <Helmet>
                <title>{appTitle('Authenticating')}</title>
            </Helmet>
            <MiniLayout narrow center>
                {invalidToken ? <p>Expired link</p> : <Spinner />}
            </MiniLayout>
            {!auth.indeterminate && auth.user && page !== 'share' && <Redirect to={redirectTo} />}
        </>
    );
};

export default Auth;
